import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3131a458 = () => interopDefault(import('..\\pages\\common.js' /* webpackChunkName: "pages/common" */))
const _7e020c46 = () => interopDefault(import('..\\pages\\quote\\index.vue' /* webpackChunkName: "pages/quote/index" */))
const _0095c0fc = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _60b37a1e = () => interopDefault(import('..\\pages\\login\\agreement.vue' /* webpackChunkName: "pages/login/agreement" */))
const _107d6bfe = () => interopDefault(import('..\\pages\\login\\forgotPwd.vue' /* webpackChunkName: "pages/login/forgotPwd" */))
const _8949cf60 = () => interopDefault(import('..\\pages\\login\\login.vue' /* webpackChunkName: "pages/login/login" */))
const _2912d639 = () => interopDefault(import('..\\pages\\login\\mailVerify.vue' /* webpackChunkName: "pages/login/mailVerify" */))
const _e7b942e8 = () => interopDefault(import('..\\pages\\login\\register.vue' /* webpackChunkName: "pages/login/register" */))
const _4df2ae71 = () => interopDefault(import('..\\pages\\login\\resetPassword.vue' /* webpackChunkName: "pages/login/resetPassword" */))
const _6994a0ca = () => interopDefault(import('..\\pages\\quote\\mixins\\index.js' /* webpackChunkName: "pages/quote/mixins/index" */))
const _ae0fc812 = () => interopDefault(import('..\\pages\\quote\\success.vue' /* webpackChunkName: "pages/quote/success" */))
const _275291f8 = () => interopDefault(import('..\\pages\\quote\\components\\configure.vue' /* webpackChunkName: "pages/quote/components/configure" */))
const _2da30544 = () => interopDefault(import('..\\pages\\quote\\components\\summary.vue' /* webpackChunkName: "pages/quote/components/summary" */))
const _265fd8ec = () => interopDefault(import('..\\pages\\quote\\components\\ww-popover.vue' /* webpackChunkName: "pages/quote/components/ww-popover" */))
const _961641b2 = () => interopDefault(import('..\\pages\\quote\\components\\ww-select.vue' /* webpackChunkName: "pages/quote/components/ww-select" */))
const _2c9ed0cc = () => interopDefault(import('..\\pages\\quote\\components\\ww-select1.vue' /* webpackChunkName: "pages/quote/components/ww-select1" */))
const _48b05032 = () => interopDefault(import('..\\pages\\quote\\mixins\\configComputed.js' /* webpackChunkName: "pages/quote/mixins/configComputed" */))
const _b18ee480 = () => interopDefault(import('..\\pages\\quote\\mixins\\imgagePrice.js' /* webpackChunkName: "pages/quote/mixins/imgagePrice" */))
const _41897d4e = () => interopDefault(import('..\\pages\\quote\\mixins\\indexComputed.js' /* webpackChunkName: "pages/quote/mixins/indexComputed" */))
const _4ff6cd91 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexData.js' /* webpackChunkName: "pages/quote/mixins/indexData" */))
const _4a457f01 = () => interopDefault(import('..\\pages\\quote\\mixins\\indexMethods.js' /* webpackChunkName: "pages/quote/mixins/indexMethods" */))
const _4dfc7695 = () => interopDefault(import('..\\pages\\quote\\mixins\\publicMixin.js' /* webpackChunkName: "pages/quote/mixins/publicMixin" */))
const _7b9784c4 = () => interopDefault(import('..\\pages\\quote\\mixins\\saveBtnValid.js' /* webpackChunkName: "pages/quote/mixins/saveBtnValid" */))
const _0fc48c12 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _ab8cb30c = () => interopDefault(import('..\\pages\\index' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/common",
    component: _3131a458,
    name: "common"
  }, {
    path: "/quote",
    component: _7e020c46,
    name: "quote"
  }, {
    path: "/test",
    component: _0095c0fc,
    name: "test"
  }, {
    path: "/login/agreement",
    component: _60b37a1e,
    name: "login-agreement"
  }, {
    path: "/login/forgotPwd",
    component: _107d6bfe,
    name: "login-forgotPwd"
  }, {
    path: "/login/login",
    component: _8949cf60,
    name: "login-login"
  }, {
    path: "/login/mailVerify",
    component: _2912d639,
    name: "login-mailVerify"
  }, {
    path: "/login/register",
    component: _e7b942e8,
    name: "login-register"
  }, {
    path: "/login/resetPassword",
    component: _4df2ae71,
    name: "login-resetPassword"
  }, {
    path: "/quote/mixins",
    component: _6994a0ca,
    name: "quote-mixins"
  }, {
    path: "/quote/success",
    component: _ae0fc812,
    name: "quote-success"
  }, {
    path: "/quote/components/configure",
    component: _275291f8,
    name: "quote-components-configure"
  }, {
    path: "/quote/components/summary",
    component: _2da30544,
    name: "quote-components-summary"
  }, {
    path: "/quote/components/ww-popover",
    component: _265fd8ec,
    name: "quote-components-ww-popover"
  }, {
    path: "/quote/components/ww-select",
    component: _961641b2,
    name: "quote-components-ww-select"
  }, {
    path: "/quote/components/ww-select1",
    component: _2c9ed0cc,
    name: "quote-components-ww-select1"
  }, {
    path: "/quote/mixins/configComputed",
    component: _48b05032,
    name: "quote-mixins-configComputed"
  }, {
    path: "/quote/mixins/imgagePrice",
    component: _b18ee480,
    name: "quote-mixins-imgagePrice"
  }, {
    path: "/quote/mixins/indexComputed",
    component: _41897d4e,
    name: "quote-mixins-indexComputed"
  }, {
    path: "/quote/mixins/indexData",
    component: _4ff6cd91,
    name: "quote-mixins-indexData"
  }, {
    path: "/quote/mixins/indexMethods",
    component: _4a457f01,
    name: "quote-mixins-indexMethods"
  }, {
    path: "/quote/mixins/publicMixin",
    component: _4dfc7695,
    name: "quote-mixins-publicMixin"
  }, {
    path: "/quote/mixins/saveBtnValid",
    component: _7b9784c4,
    name: "quote-mixins-saveBtnValid"
  }, {
    path: "/",
    component: _0fc48c12,
    name: "index"
  }, {
    path: "/",
    component: _ab8cb30c,
    name: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
